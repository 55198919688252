/*!
* Version: 5.2.0
* Template: SocialV - Responsive Bootstrap 5 Admin Dashboard Template
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for responsive Template.
*
*/

// Configuration
@import "./bootstrap/functions";

@import "./customizer/variables";
@import "./customizer/root";
