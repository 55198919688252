.accordion-item {
	border: 0;

	@if $enable-rounded ==true {
		@include radius-mixin($border-radius);
	}

	&:not(:first-of-type) {
		border: 0;
	}
	.accordion-button {
		background: var(--#{$prefix}gray-900);
		border: 0;
		&:not(.collapsed) {
			color: var(--bs-primary);
			background-color: var(--bs-primary-bg-subtle);
		}
	}
	.accordion-body {
		background: var(--#{$prefix}gray-900);
	}
}