.avatar {
    position: relative;
    object-fit: cover;
}
@each $size,$value in $avatar-size {
    .avatar-#{$value}{@include avatar($value, $border-radius-sm)}
}
.avatar-borderd{
    border: 2px solid;
    border-color: var(--bs-border-color);
}
@each $size, $value in $border-widths {
    .avatar-borderd-#{$size}{
        border: $value solid;
        border-color: rgba(var(--bs-primary-rgb), 0.2);
    }
}
.avatar-rounded {
    border-radius: $border-radius-pill;
    overflow: hidden;
}

.object-contain{
	object-fit: contain;
}

.object-cover{
	object-fit: cover;
}