.product {
	.offer {
		height: 3.125rem;
		width: 3.125rem;
		line-height: 3.125rem;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-pill);
		}
		font-size: $font-size-base;
		font-weight: 700;
		text-align: center;
		color: $dark;
		background: $white;
		@if $enable-shadows == true {
			@include shadow-mixin(0px 0px 20px 0px ($primary,0.1));
		}
		// box-shadow: 0px 0px 20px 0px ($primary,0.1);
		position: absolute;
		top: 0.625rem;
		left: 0.625rem;
	}
	p.categry {
		&:before {
			content: "";
			height:.5rem;
			width:.5rem;
			background: $white;
			border: 2px solid var(--#{$variable-prefix}primary);
			position: absolute;
			left: 0;
			top:.5625rem;
			@if $enable-rounded == true {
				@include radius-mixin($border-radius-pill);
			}
		}
	}
	.price {
		position: absolute;
		right: -0.5rem;
		bottom: 0.938rem;
		padding:0.125rem 0.625rem;
		display: inline-block;
		color: $white;
		background: var(--#{$variable-prefix}primary);		
		@if $enable-shadows == true {
			@include shadow-mixin(0px 0px 20px 0px rgba(44, 101, 144, 0.1));
		}
		&:before {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 0px 0px 8px;
			border-color: transparent transparent transparent var(--#{$variable-prefix}primary);
			position: absolute;
			left: auto;
			right: 0;
			top: -0.5rem;
			margin: 0 auto;
		}
		&:after {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0px 0px 8px 8px;
			border-color: transparent transparent transparent var(--#{$variable-prefix}primary);
			position: absolute;
			left: auto;
			right: 0;
			bottom: -0.5rem;
			margin: 0 auto;
		}
	}
	.regular-price {
		text-decoration: line-through;
		font-size: $font-size-base;
	}
}
#quantity {
	display: inline-block;
	width: 1.875rem;
	height: 1.75rem;
	border: 1px solid $border-color;
	text-align: center;
}
button.qty-btn {
	border: 1px solid $border-color;
	background: $white;
	height: 1.75rem;
	width: 1.875rem;
	vertical-align: top;
}
.form-add-btn {
	margin-bottom: 1.25rem;
}

.product-size {
	width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
	font-weight: $font-weight-semibold;
	border-radius: 50%;
	background-color: var(--bs-light-bg-subtle);
	color: var(--bs-body-color);
	margin-right: .625rem;
	border: 1px solid transparent;
	&.active,
	&:hover {
		border-color: var(--bs-primary);
	}
}

.product-color {
	width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
	border: .4375rem solid var(--bs-white);
	border-radius: 50%;
	display: block;
}

.iq-qty-btn .input-display {
    width: 2.5rem;
    cursor: text;
    color: var(--bs-body-color);
}

.store-detail-table {
	tr, td, th {
		padding-bottom: 1.5rem;
	}
}

.product {
	.product-description {
		a {
			color: var(--bs-heading-color);
			font-weight: $font-weight-medium;
			font-size: 1rem;
		}
	}
}