#loading {
	background-color: var(--bs-body-bg);
	height: 100%;
	width: 100%;
	position: fixed;
	margin-top: 0px;
	top: 0px;
	left: 0px;
	bottom: 0px;
	overflow: hidden !important;
	right: 0px;
	z-index: $zindex-tooltip;
}
#loading-center {
	background: url(../../../../images/loader.png) no-repeat scroll center center;
	background-size: 20%;
	width: 100%;
	height: 100%;
	position: relative;
}
.loader {
	width: 3em;
	height: 3em;
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	position: absolute;
}

// custom loder
/*------------------*/
@keyframes loader1 {
	0%,100% {
		box-shadow: 0em -2.6em 0em 0em #4788ff, 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2), 2.5em 0em 0 0em rgba(71, 136, 255, 0.2), 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.2), 0em 2.5em 0 0em rgba(71, 136, 255, 0.2), -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.2), -2.6em 0em 0 0em rgba(71, 136, 255, 0.5), -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.7);
	}
	12.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.7), 1.8em -1.8em 0 0em #4788ff, 2.5em 0em 0 0em rgba(71, 136, 255, 0.2), 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.2), 0em 2.5em 0 0em rgba(71, 136, 255, 0.2), -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.2), -2.6em 0em 0 0em rgba(71, 136, 255, 0.2), -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.5);
	}
	25% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.5), 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.7), 2.5em 0em 0 0em #4788ff, 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.2), 0em 2.5em 0 0em rgba(71, 136, 255, 0.2), -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.2), -2.6em 0em 0 0em rgba(71, 136, 255, 0.2), -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2);
	}
	37.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.2), 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.5), 2.5em 0em 0 0em rgba(71, 136, 255, 0.7), 1.75em 1.75em 0 0em #4788ff, 0em 2.5em 0 0em rgba(71, 136, 255, 0.2), -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.2), -2.6em 0em 0 0em rgba(71, 136, 255, 0.2), -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2);
	}
	50% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.2), 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2), 2.5em 0em 0 0em rgba(71, 136, 255, 0.5), 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.7), 0em 2.5em 0 0em #4788ff, -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.2), -2.6em 0em 0 0em rgba(71, 136, 255, 0.2), -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2);
	}
	62.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.2), 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2), 2.5em 0em 0 0em rgba(71, 136, 255, 0.2), 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.5), 0em 2.5em 0 0em rgba(71, 136, 255, 0.7), -1.8em 1.8em 0 0em #4788ff, -2.6em 0em 0 0em rgba(71, 136, 255, 0.2), -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2);
	}
	75% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.2), 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2), 2.5em 0em 0 0em rgba(71, 136, 255, 0.2), 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.2), 0em 2.5em 0 0em rgba(71, 136, 255, 0.5), -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.7), -2.6em 0em 0 0em #4788ff, -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2);
	}
	87.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.2), 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2), 2.5em 0em 0 0em rgba(71, 136, 255, 0.2), 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.2), 0em 2.5em 0 0em rgba(71, 136, 255, 0.2), -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.5), -2.6em 0em 0 0em rgba(71, 136, 255, 0.7), -1.8em -1.8em 0 0em #4788ff;
	}
}

@-webkit-keyframes loader1 {
	0%,100% {
		box-shadow: 0em -2.6em 0em 0em #4788ff, 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2), 2.5em 0em 0 0em rgba(71, 136, 255, 0.2), 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.2), 0em 2.5em 0 0em rgba(71, 136, 255, 0.2), -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.2), -2.6em 0em 0 0em rgba(71, 136, 255, 0.5), -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.7);
	}
	12.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.7), 1.8em -1.8em 0 0em #4788ff, 2.5em 0em 0 0em rgba(71, 136, 255, 0.2), 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.2), 0em 2.5em 0 0em rgba(71, 136, 255, 0.2), -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.2), -2.6em 0em 0 0em rgba(71, 136, 255, 0.2), -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.5);
	}
	25% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.5), 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.7), 2.5em 0em 0 0em #4788ff, 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.2), 0em 2.5em 0 0em rgba(71, 136, 255, 0.2), -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.2), -2.6em 0em 0 0em rgba(71, 136, 255, 0.2), -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2);
	}
	37.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.2), 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.5), 2.5em 0em 0 0em rgba(71, 136, 255, 0.7), 1.75em 1.75em 0 0em #4788ff, 0em 2.5em 0 0em rgba(71, 136, 255, 0.2), -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.2), -2.6em 0em 0 0em rgba(71, 136, 255, 0.2), -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2);
	}
	50% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.2), 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2), 2.5em 0em 0 0em rgba(71, 136, 255, 0.5), 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.7), 0em 2.5em 0 0em #4788ff, -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.2), -2.6em 0em 0 0em rgba(71, 136, 255, 0.2), -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2);
	}
	62.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.2), 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2), 2.5em 0em 0 0em rgba(71, 136, 255, 0.2), 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.5), 0em 2.5em 0 0em rgba(71, 136, 255, 0.7), -1.8em 1.8em 0 0em #4788ff, -2.6em 0em 0 0em rgba(71, 136, 255, 0.2), -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2);
	}
	75% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.2), 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2), 2.5em 0em 0 0em rgba(71, 136, 255, 0.2), 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.2), 0em 2.5em 0 0em rgba(71, 136, 255, 0.5), -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.7), -2.6em 0em 0 0em #4788ff, -1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2);
	}
	87.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(71, 136, 255, 0.2), 1.8em -1.8em 0 0em rgba(71, 136, 255, 0.2), 2.5em 0em 0 0em rgba(71, 136, 255, 0.2), 1.75em 1.75em 0 0em rgba(71, 136, 255, 0.2), 0em 2.5em 0 0em rgba(71, 136, 255, 0.2), -1.8em 1.8em 0 0em rgba(71, 136, 255, 0.5), -2.6em 0em 0 0em rgba(71, 136, 255, 0.7), -1.8em -1.8em 0 0em #4788ff;
	}
}

@-webkit-keyframes loader2 {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(360deg));
	}
}
@keyframes loader2 {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(360deg));
	}
}
@-webkit-keyframes loader-3-outter {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(360deg));
	}
}
@keyframes loader-3-outter {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(360deg));
	}
}
@-webkit-keyframes loader-3-inner {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(-360deg));
	}
}
@keyframes loader-3-inner {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(-360deg));
	}
}
@-webkit-keyframes loader4 {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(360deg));
	}
}
@keyframes loader4 {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(360deg));
	}
}
@keyframes loader5 {
	0%,100% {
		@include transform-mixin( translateX(0px));
	}
	75% {
		@include transform-mixin( translateX(30px));
	}
}
@keyframes loader5_1 {
	0%,100% {
		@include transform-mixin( translateY(0px));
	}
	75% {
		@include transform-mixin( translateY(30px));
	}
}
@keyframes loader5_2 {
	0%,100% {
		@include transform-mixin( translate(0px, 0px));
	}
	75% {
		@include transform-mixin( translate(30px, 30px));
	}
}
@keyframes inner1 {
	0% {
		@include transform-mixin( rotateX(35deg) rotateY(-45deg) rotateZ(0deg));
	}
	100% {
		@include transform-mixin( rotateX(35deg) rotateY(-45deg) rotateZ(360deg));
	}
}
@keyframes inner2 {
	0% {
		@include transform-mixin( rotateX(50deg) rotateY(10deg) rotateZ(0deg));
	}
	100% {
		@include transform-mixin( rotateX(50deg) rotateY(10deg) rotateZ(360deg) );
	}
}
@keyframes inner3 {
	0% {
		@include transform-mixin( rotateX(35deg) rotateY(55deg) rotateZ(0deg));
	}
	100% {
		@include transform-mixin( rotateX(35deg) rotateY(55deg) rotateZ(360deg));
	}
}
@keyframes loader7 {
	0% {
		@include transform-mixin( rotate(0));
	}
	50% {
		@include transform-mixin( rotateY(180deg) );
	}
	100% {
		@include transform-mixin( rotateY(180deg) rotateX(180deg));
	}
}
@-webkit-keyframes loader8 {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(360deg));
	}
}
@keyframes loader8 {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(360deg));
	}
}
@-webkit-keyframes loader9 {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(360deg));
	}
}
@keyframes loader9 {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(360deg));
	}
}
@keyframes loader10 {
	0%,100% {
		@include transform-mixin( translate(0, 0) rotate(0));
	}
	25% {
		@include transform-mixin( translate(40px, 40px) rotate(45deg));
	}
	50% {
		@include transform-mixin( translate(0px, 80px) rotate(0deg));
	}
	75% {
		@include transform-mixin( translate(-40px, 40px) rotate(45deg));
	}
}
@keyframes loader11 {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(360deg));
	}
}
@keyframes loader12 {
	0% {
		@include transform-mixin( rotate(0deg));
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-pill);
		}
	}
	50% {
		@include transform-mixin( rotate(90deg));
		border-radius: 0%;
	}
	100% {
		@include transform-mixin(rotate(180deg));
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-pill);
		}
	}
}
@-webkit-keyframes loader13 {
	to {
		@include transform-mixin( rotate(360deg));
	}
}
@keyframes loader13 {
	to {
		@include transform-mixin( rotate(360deg));
	}
}
@-webkit-keyframes loader14 {
	0% {
		@include transform-mixin( scale(0));
		@include opacity-mixin(0);
	}
	50% {
		@include opacity-mixin(1);
	}
	100% {
		@include transform-mixin( scale(1));
		@include opacity-mixin(0);
	}
}
@keyframes loader14 {
	0% {
		@include transform-mixin( scale(0));
		@include opacity-mixin(0);
	}
	50% {
		@include opacity-mixin(1);
	}
	100% {
		@include transform-mixin( scale(1));
		@include opacity-mixin(0);
	}
}
@-webkit-keyframes loader15-inner {
	0% {
		@include transform-mixin( scale(0));
	}
	100% {
		@include transform-mixin( rotate(-360deg));
	}
}
@keyframes loader15-inner {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(-360deg));
	}
}
@-webkit-keyframes loader15 {
	0% {
		border-width: 0px;
	}
	100% {
		border-width: 10px;
	}
}
@keyframes loader15 {
	0% {
		border-width: 0px;
	}
	100% {
		border-width: 10px;
	}
}
@-webkit-keyframes loader16 {
	0% {
		@include transform-mixin( rotate(-360deg));
	}
	100% {
		@include transform-mixin( rotate(360deg));
	}
}
@keyframes loader16 {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(360deg));
	}
}
.iq-loader-box {
	height: 150px;
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	vertical-align: middle;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	[class*="loader-"] {
		display: inline-block;
		width: 3.75rem;
		height: 3.75rem;
		color: inherit;
		vertical-align: middle;
		position: relative;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-pill);
		}
	}
	.iq-loader-1 {
		width: 1em;
		height: 1em;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-pill);
		}
		position: relative;
		text-indent: -9999em;
		-webkit-animation: loader1 1.1s infinite ease;
		animation: loader1 1.1s infinite ease;
		@include transform-mixin( translateZ(0));
	}
	.iq-loader-2 {
		border: 7px double #37e6b0;
		-webkit-animation: loader2 1s linear infinite;
		animation: loader2 1s linear infinite;
		&:before {
			content: "";
			position: absolute;
			width: 12px;
			height: 12px;
			background: #37e6b0;
			@if $enable-rounded == true {
				@include radius-mixin($border-radius-pill);
			}
			bottom: 0;
			right: 37px;
		}
		&:after {
			content: "";
			position: absolute;
			width: 12px;
			height: 12px;
			background: #37e6b0;
			@if $enable-rounded == true {
				@include radius-mixin($border-radius-pill);
			}
			bottom: 0;
			right: 37px;
			left: 37px;
			top: 0;
		}
	}
	.iq-loader-3 {
		position: relative;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-pill);
		}
		display: inline-block;
		vertical-align: middle;
		.loader-outter {
			position: absolute;
			border: 4px solid #f50057;
			border-left-color: transparent;
			border-bottom: 0;
			width: 100%;
			height: 100%;
			@if $enable-rounded == true {
				@include radius-mixin($border-radius-pill);
			}
			-webkit-animation: loader-3-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
			animation: loader-3-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
		}
		.loader-inner {
			position: absolute;
			border: 4px solid #f50057;
			@if $enable-rounded == true {
				@include radius-mixin($border-radius-pill);
			}
			width: 40px;
			height: 40px;
			left: calc(50% - 1.25rem);
			top: calc(50% - 1.25rem);
			border-right: 0;
			border-top-color: transparent;
			-webkit-animation: loader-3-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
			animation: loader-3-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
		}
	}
	.iq-loader-4 {
		border: .2em solid transparent;
		border-top-color: #fe721c;
		border-bottom-color: #fe721c;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-pill);
		}
		position: relative;
		-webkit-animation: 1s loader4 linear infinite;
		animation: 1s loader4 linear infinite;
		&:before {
			content: '';
			display: block;
			width: 0;
			height: 0;
			position: absolute;
			border: .2em solid transparent;
			border-bottom-color: #fe721c;
			-webkit-transform: rotate(135deg);
			transform: rotate(135deg);
			right: -.3em;
			top: -.05em;
		}
		&:after {
			content: '';
			display: block;
			width: 0;
			height: 0;
			position: absolute;
			border: .2em solid transparent;
			border-bottom-color: #fe721c;
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
			left: -.3em;
			bottom: -.05em;
		}
	}
	.iq-loader-5 {
		width: 100px;
		height: 100px;
		@if $enable-rounded == true {
			@include radius-mixin(100%);
		}
		position: relative;
		margin: 0 auto;
		span {
			display: block;
			position: absolute;
			left: calc(50% - 1.25rem);
			top: calc(50% - 1.25rem);
			width: 1.25rem;
			height: 1.25rem;
			background-color: $info;
			&:nth-child(1) {
				animation: loader5 1s ease-in-out infinite;
			}
			&:nth-child(2) {
				animation: loader5_1 1s ease-in-out infinite;
			}
			&:nth-child(3) {
				animation: loader5_2 1s ease-in-out infinite;
			}
		}
	}
	.iq-loader-6 {
		.inner {
			position: absolute;
			box-sizing: border-box;
			width: 100%;
			height: 100%;
			@if $enable-rounded == true {
				@include radius-mixin($border-radius-pill);
			}
		}
		.inner1 {
			left: 0%;
			top: 0%;
			animation: inner1 1s linear infinite;
			border-bottom: 3px solid #ff4b4b;
		}
		.inner2 {
			right: 0%;
			top: 0%;
			animation: inner2 1s linear infinite;
			border-right: 3px solid #ff4b4b;
		}
		.inner3 {
			right: 0%;
			bottom: 0%;
			animation: inner3 1s linear infinite;
			border-top: 3px solid #ff4b4b;
		}
	}
	.iq-loader-7 {
		&:before {
			content: "";
			position: absolute;
			left: 0px;
			width: 50px;
			height: 50px;
			background-color: #3498db;
			animation: loader7 1s infinite;
		}
	}
	.iq-loader-8 {
		border: 1.2em solid transparent;
		border-left-color: #d0bac2;
		border-right-color: #d0bac2;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-pill);
		}
		-webkit-animation: 1s loader8 linear infinite;
		animation: 1s loader8 linear infinite;
	}
	.iq-loader-9 {
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-pill);
		}
		width: 7em;
		height: 7em;
		margin: 3.75rem auto;
		font-size: 10px;
		position: relative;
		text-indent: -9999em;
		border-top: 1.1em solid rgba(255, 107, 208, 0.2);
		border-right: 1.1em solid rgba(255, 107, 208, 0.2);
		border-bottom: 1.1em solid rgba(255, 107, 208, 0.2);
		border-left: 1.1em solid #ff6bd0;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-animation: loader9 1.1s infinite linear;
		animation: loader9 1.1s infinite linear;
		&:after {
			@if $enable-rounded == true {
				@include radius-mixin($border-radius-pill);
			}
			width: 7em;
			height: 7em;
		}
	}
	.iq-loader-10 {
		&:before {
			content: "";
			width: 1.25rem;
			height: 1.25rem;
			position: absolute;
			top: -10px;
			left: calc(50% - 10px);
			background-color: #3450db;
			animation: loader10 1s ease-in-out infinite;
		}
		&:after {
			content: "";
			width: 1.25rem;
			height: 1.25rem;
			position: absolute;
			top: -10px;
			left: calc(50% - 10px);
			background-color: #3450db;
			animation: loader10 1s ease-in-out infinite;
			bottom: 0;
			animation-delay: 0.5s;
		}
	}
	.iq-loader-11 {
		background: $black;
		&:before {
			content: '';
			position: absolute;
			@if $enable-rounded == true {
				@include radius-mixin($border-radius-pill);
			}
			width: 1.563rem;
			height: 1.563rem;
			margin: 0 0 0 -12px;
			background-color:$white;
			top: auto;
			bottom: 0;
			left: 50%;
			transform-origin: 50% 0%;
			animation: loader11 1s infinite linear;
		}
	}
	.iq-loader-12 {
		animation: loader12 1s infinite linear;
		background: #6dca5c;
	}
	.iq-loader-13 {
		border-top: 0.2em solid #a7a4ca;
		border-right: .2em solid transparent;
		-webkit-animation: loader13 1s linear infinite;
		animation: loader13 1s linear infinite;
		@if $enable-rounded == true {
			@include radius-mixin(100%);
		}
		position: relative;
	}
	.iq-loader-14 {
		border: 0 solid transparent;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-pill);
		}
		position: relative;
		&:before {
			content: '';
			border: 1em solid #13d7eb;
			@if $enable-rounded == true {
				@include radius-mixin($border-radius-pill);
			}
			width: inherit;
			height: inherit;
			position: absolute;
			top: 0;
			left: 0;
			-webkit-animation: loader14 1s linear infinite;
			animation: loader14 1s linear infinite;
			@include opacity-mixin(0);
			-webkit-animation-delay: 1s;
			animation-delay: 1s;
		}
		&:after {
			content: '';
			border: 1em solid #13d7eb;
			@if $enable-rounded == true {
				@include radius-mixin($border-radius-pill);
			}
			width: inherit;
			height: inherit;
			position: absolute;
			top: 0;
			left: 0;
			-webkit-animation: loader14 1s linear infinite;
			animation: loader14 1s linear infinite;
			@include opacity-mixin(0);
			-webkit-animation-delay: .5s;
			animation-delay: .5s;
		}
	}
	.iq-loader-15 {
		border: 6px groove #7e57c2;
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
		@if $enable-transitions == true {
			@include transition-mixin(all 0.1s ease-in-out);
		}
		-webkit-animation: loader15-inner 1s ease-out alternate infinite;
		animation: loader15-inner 1s ease-out alternate infinite;
		.loader-inner {
			border: 0px inset #9575cd;
			@if $enable-rounded == true {
				@include radius-mixin($border-radius-pill);
			}
			width: 100%;
			height: 100%;
			-webkit-animation: loader15 1s ease-out alternate infinite;
			animation: loader15 1s ease-out alternate infinite;
		}
	}
	.iq-loader-16 {
		width: 70px;
		height: 70px;
		border: 5px dashed #b3a57c;
		-webkit-animation: loader16 2s linear both infinite;
		animation: loader16 2s linear both infinite;
	}
}

