.iq-top-navbar {
  position: sticky;
  top: 0;
  min-height: 3rem;
  left: auto;
  right: 0;
  width: 100%;
  display: inline-block;
  background: var(--#{$prefix}gray-900);
  z-index: 100;
  box-shadow: none;

  @if $enable-transitions ==true {
    @include transition-mixin(all 0.3s ease-in-out);
  }

  .navbar-expand-lg {
    justify-content: space-between !important;
  }

  .navbar-collapse {
    flex-grow: unset;
  }

  .iq-navbar-custom {
    .navbar-breadcrumb {
      display: block;
      margin-left: 1.875rem;
      padding: 0.625rem 0;
    }
  }

  .breadcrumb {
    .breadcrumb-item.active {
      color: var(--bs-body-color);
    }

    background: transparent;
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
      font-size: 0.75rem;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .breadcrumb-item+.breadcrumb-item {
    &::before {
      content: "\e88a";
      font-family: "Material Design Icons";
      font-size: $font-size-base;
      vertical-align: sub;
      color: var(--bs-body-color);
    }
  }
  .navbar-nav {
    .notification-badge {
      height: .375rem;
      width: .375rem;
      border-radius: 50rem;
      position: absolute;
      top: .125rem;
      left: auto;
      right: .25rem;
    }
    .shopping-badge {
      position: absolute;
      border-radius: 50rem;
      height: .875rem;
      width: .875rem;
      line-height: .8125rem;
      font-size: .5rem;
      text-align: center;
      top: -.0625rem;
      left: auto;
      right: -.125rem;
    }
  }
}

.iq-top-navbar.fixed-header {
  margin: 0;
  right: 0;
  width: calc(100% - 16.25rem);
  @include radius-mixin(0);

  @if $enable-transitions ==true {
    @include transition-mixin(all 0.3s ease-in-out);
  }
}

.navbar-list {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    position: relative;

    >.dropdown {
      .dropdown-menu {
        -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        @include transform-mixin(translateY(100%));
      }

      line-height: 4.563rem;
      cursor: pointer;
    }

    .dropdown-toggle {
      &::after {
        display: none;
      }
    }

    >a.language-title {
      font-size: 1rem;
    }

    >a {
      font-size: 1.125rem;
      padding: 0 .5rem;
      color: var(--bs-body-color);
      line-height: 4.563rem;
      display: block;
      min-height: 4.688rem;

      >img {
        width: 3.125rem;
        height: 3.125rem;
      }
    }

    &:last-child {
      >a {
        padding-right: 0rem;
      }
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }

    .caption {
      line-height: 1rem;
    }
  }

  li.iq-nav-user {
    display: flex;
    align-items: flex-start;

    span {
      flex: 1;
      font-size: $font-size-base;
    }
  }
}

.navbar-light .navbar-brand {
  color: var(--bs-primary);
}

.iq-breadcrumb {
  display: none;
}

.nav-item {
  span.dots {
    height: 0.313rem;
    width: 0.313rem;
    font-size: 0;
    text-align: center;
    padding: 0;
    position: absolute;
    top: 1.563rem;
    right: 0.625rem;
    animation: shadow-pulse-dots 1s infinite;
    @include radius-mixin($border-radius-pill);
  }

  span.count-mail {
    height: 0.313rem;
    width: 0.313rem;
    font-size: 0.625rem;
    text-align: center;
    line-height: 0.938rem;
    padding: 0;
    position: absolute;
    top: 1.25rem;
    right: 0.625rem;
    animation: shadow-pulse 1s infinite;
    @include radius-mixin($border-radius-pill);
  }
}

.top-nav-bottom {
  margin-left: 16.25rem;
  border-top: 1px solid rgba(var(--#{$variable-prefix}primary-rgb), 0.2);
}

.topnav-toolbar {
  li {
    a {
      display: block;
      padding: 0.625rem 0.938rem;
      text-transform: capitalize;
      color: $secondary;
      @include radius-mixin(10px);
    }
  }

  li.date-today {
    background: $light;
  }

  li.active {
    a {
      background: rgba(var(--#{$variable-prefix}primary-rgb), 0.2);
      color: var(--#{$variable-prefix}primary);
    }
  }

  ul {
    li {
      list-style: none;
      padding: 0.938rem 0;
    }
  }
}

.iq-search-bar {
  .searchbox {
    position: relative;
    .search-link {
      position: absolute;
      left: 1.25rem;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      color: var(--bs-body-color);
    }
  }

  .search-input {
    width: 100%;
    height: 2.5rem;
    padding: .8125rem 1.25rem .8125rem 3.125rem;
    @include radius-mixin($border-radius);
    border: none;
    border: 1px solid var(--#{$prefix}border-color);
    font-weight: $font-weight-semibold;
    color: var(--bs-body-color);
  }
}

.iq-search-bar input.search-input::placeholder {
  opacity: 0.5;
}

.iq-navbar-logo {
  padding: 1em;
  width: auto;
  position: relative;
  background: $white;

  @if $enable-transitions ==true {
    @include transition-mixin(all 0.3s ease-in-out);
  }

  background: transparent;

  a {
    display: flex;
    align-items: flex-start;

    span {
      font-family: $headings-font-family;
      margin-left: 0.625rem;
      font-size: 1.75rem;
      line-height: 2.813rem;
      color: $headings-color;
      flex: 1;

      @if $enable-transitions ==true {
        @include transition-mixin(all 0.3s ease-in-out);
      }
    }

    &:hover {
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }
  }

  img {
    height: 2.813rem;
  }
}

.iq-top-navbar {
  .sub-drop {
    width: 18.75rem;
    position: absolute;
    border: none;
    top: 100%;
    right: 0;
    left: auto;
    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    background-color: $white;
    padding: 0;

    @if $enable-rounded ==true {
      @include radius-mixin($border-radius);
    }

    @if $enable-shadows ==true {
      @include shadow-mixin($box-shadow);
    }

    @if $enable-transitions ==true {
      @include transition-mixin(all 0.3s ease-in-out);
    }

    .card-body {
      div {
        @if $enable-rounded ==true {
          @include radius-mixin(5px 5px 0 0);
        }
      }
    }

    .header-title {
      line-height: normal;
    }

    &.header-notification {
      width: 24.9801rem;
    }
  }
}

.sub-drop.sub-drop-large {
  width: 31.25rem;
}

.navbar-list {
  li {
    .sub-drop {
      .iq-sub-card.iq-sub-card-big {
        width: auto;
      }

      .iq-sub-card {
        font-size: inherit;
        padding: 1rem;
        line-height: normal;
        color: inherit;
        display: inline-block;
        width: 100%;
      }
    }
  }
}

.iq-top-navbar {
  .search-box {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    @include transform-mixin(translateY(100%));
    background-color: $white;
    padding: 0.938rem;

    @if $enable-shadows ==true {
      @include shadow-mixin($box-shadow);
    }

    @if $enable-transitions ==true {
      @include transition-mixin(all 0.3s ease-in-out);
    }

    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
}

.search-box {
  .search-input {
    width: 18.75rem;
    height: 4.688rem;
    padding: 0 0.938rem;
    border: 1px solid rgba(var(--#{$variable-prefix}primary-rgb), 0.2);
    background-color: rgba(var(--#{$variable-prefix}primary-rgb), 0.2);
    @include opacity-mixin(1);

    @if $enable-rounded ==true {
      @include radius-mixin($border-radius);
    }
  }
}

.sub-drop {
  .card-icon {
    width: 2.813rem;
    height: 2.813rem;
    line-height: 2.813rem;
    font-size: 1.375rem;
  }
}

.header-for-bg {
  .title-on-header {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    text-align: center;
    color: $white;
  }

  .data-block {
    display: inline-block;
    background: var(--#{$prefix}gray-900);
    padding: 1.25rem;

    @if $enable-rounded ==true {
      @include radius-mixin($border-radius);
    }
  }
}

.profile-header-image {
  .profile-img {
    margin-top: -4.375rem;
    position: relative;
  }
}

.nav-tabs {
  margin-bottom: 0.938rem;
}

.members-search {
  .searchbox {
    .search-link {
      top: 50%;
    }
  }
}

@media (min-width: 1199px) {
  .iq-top-navbar {
    .test {
      a {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .iq-search-bar {
    padding-left: 0rem;
  }

  .iq-top-navbar {
    width: 100%;
    left: 0;
    right: 0;
    margin-left: 0;

    .iq-navbar-custom {
      .navbar-breadcrumb {
        display: none;
      }
    }
  }

  .iq-top-navbar.fixed-header {
    width: 100%;
    left: 0;
  }

  .iq-sidebar {
    display: inline-block;
    z-index: 99;
    left: -16.25rem;
  }

  .iq-sidebar-logo {
    width: auto;
  }
}

@include media-breakpoint-down(lg) {
  .iq-top-navbar {
    .navbar {
      position: relative;
      padding-bottom: 0;

      .navbar-inner {
        display: block;
        padding: 0 1rem;
      }
    }

    .iq-navbar-custom {
      .iq-menu-bt {
        right: 11.563rem;
        top: 1.125rem;
      }
    }

    .navbar-nav {
      &.navbar-list {
        li {
          &:first-child {
            .sub-drop {
              right: auto;
              left: 0;
            }
          }
        }
      }
    }
  }

  .navbar-collapse {
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: $white;

    @if $enable-shadows ==true {
      @include shadow-mixin($box-shadow);
    }
  }

  .navbar-nav.navbar-list {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--#{$prefix}border-color);

    li.user-dropdown {
      position: absolute;
      top: 0;
      left: auto;
      right: 0;
    }
  }

  .sub-drop.sub-drop-large {
    width: 27.5rem;
  }

  div.sidebar-toggle {
    display: block;
  }

  .navbar-list {
    li {
      .caption {
        margin-left: 1rem;
      }

      img {
        margin-right: 0 !important;
      }

      &:last-child {
        >a {
          padding-right: 1rem;
        }
      }

      >a {
        font-size: 1.125rem;
        padding: 0.5rem 0rem;
        line-height: 3rem;
        display: block;
        min-height: 0;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .iq-header-logo {
    svg {
      width: 3rem;
    }
  }

  .search-box {
    .search-input {
      width: 17.5rem;
    }
  }

  .iq-navbar-custom {
    margin-top: 0.938rem;
  }

  .navbar-collapse {
    margin-top: 0.813rem;
  }

  .navbar-list {
    li {
      >a {
        font-size: 1.125rem;
        padding: 0.5rem 0rem;
        line-height: 3rem;
        display: block;
        min-height: 0;
      }
    }
  }

  .iq-sub-card.iq-sub-card-big {
    div {
      + {
        div {
          margin-top: 0.625rem;
        }
      }
    }
  }

  .sub-drop.sub-drop-large {
    width: 18.75rem;
  }

  .navbar-list {
    li {
      position: static;
    }
  }

  .iq-top-navbar {
    .sub-drop {
      width: 100%;
      right: 0;
      top: 100% !important;

      &.caption-menu {
        right: 0;
      }

      &.header-notification {
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .iq-sub-card.iq-sub-card-big {
    display: block !important;
  }

  .navbar-list {
    li.user-dropdown {
      .sub-drop {
        width: 18.75rem;
      }
    }
  }

  .iq-sidebar-logo {
    a {
      span {
        display: none;
      }
    }
  }

  .iq-top-navbar {
    .sub-drop.sub-drop-large {
      right: 0;
      left: 0;
    }

    .sub-drop.iq-user-dropdown {
      right: 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .iq-search-bar {
    .searchbox {
      width: 31em;
    }
  }
}

/*user dropdown */
.iq-bg-primary-hover:hover {
  background: rgba(var(--#{$variable-prefix}primary-rgb), 0.1);
}

.iq-bg-warning-hover:hover {
  background: rgba($warning, 0.1);
}

.iq-bg-info-hover:hover {
  background: rgba($info, 0.1);
}

.iq-bg-danger-hover:hover {
  background: rgba($danger, 0.1);
}

.top-header-responsive {
  bottom: 0;
  z-index: 10;
  position: fixed;
  width: 100%;
  box-shadow: 0 -10px 30px rgba($black, 0.08);
  left: 0;
  padding: 1em 0em;
  background-color: $white;

  ul.menu-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    line-height: normal;

    li {
      a {
        color: $headings-color;

        &.active {
          color: $primary;
        }
      }
    }
  }
}

.iq-nav-menu {
  display: flex;
  flex-direction: column;

  li {
    position: relative;

    &.active {
      >a {
        color: var(--#{$variable-prefix}primary);
      }
    }

    .nav-link {
      &.active {
        color: var(--#{$variable-prefix}primary);
        font-weight: 500;
      }
    }
  }

  >li {
    >a {
      font-weight: $font-weight-semibold;
      color: var(--bs-body-color);
    }
  }

  a {
    position: relative;
    display: block;
    padding: 0.625rem 1.875rem;
    outline-width: 0;
    color: var(--bs-body-color);
    text-decoration: none;
    font-weight: $font-weight-medium;
  }

  .menu-arrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
  }

  ul {
    transition: all 0.2s ease-in-out;
    position: relative;
    z-index: 1;
    padding: 0.5rem 0;
    margin-left: 1.5rem;
  }
}

.ecommbnav-scroll {
  position: relative;
  height: 100%;
}

.iq-nav-menu {
  .nav-item {
    >.nav-link {
      &:hover {
        color: var(--#{$variable-prefix}heading-color);
      }
    }
  }
}

.screen-darken {
  height: 100%;
  width: 0%;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(33, 37, 41, 0.6);
  transition: opacity 0.2s linear, visibility 0.2s, width 2s ease-in;

  &.active {
    z-index: 10;
    -webkit-transition: opacity 0.3s ease, width 0s;
    -o-transition: opacity 0.3s ease, width 0s;
    transition: opacity 0.3s ease, width 0s;
    opacity: 1;
    width: 100%;
    visibility: visible;
  }
}

@include media-breakpoint-up(xl) {
  .iq-nav-menu {
    flex-direction: row;
    margin-bottom: 0;
    display: inline-flex;
    flex-wrap: wrap;

    li {
      position: relative;
      flex-direction: column;

      >ul {
        position: absolute;
        height: auto !important;
        top: 100%;
        width: 100%;
        min-width: 17.75rem;
        z-index: 999;
        left: auto;
        right: auto;
        padding: 0.5rem 0;
        display: none;
        margin: 0;
        background: var(--bs-gray-900);
        @include border-radius($dropdown-border-radius);
        @include box-shadow($dropdown-box-shadow);
        animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1,
          menu-sub-dropdown-animation-move-up 0.3s ease 1;

        ul {
          left: 100%;
          top: 0;
          box-shadow: 0rem 0.25rem 2rem 0rem rgba($black, 0.1);
        }

        li {
          padding: 0;

          a {
            transition: var(--sidebar-transition);
            transition-duration: var(--sidebar-transition-duration);
            transition-timing-function: var(--sidebar-transition-function-ease-in-out);
            padding: 0.5rem 1.25rem 0.5rem 1.25rem;
          }
        }
      }

      .iq-header-sub-menu {
        .nav-item {
          >.nav-link {
            &:hover {
              padding-left: 1.5rem;
              font-weight: 500;
              transition: var(--sidebar-transition);
              transition-duration: var(--sidebar-transition-duration);
              transition-timing-function: var(--sidebar-transition-function-ease-in-out);
            }
          }
        }
      }

      &:hover {
        >ul {
          display: block;
        }
      }

      .nav-link {
        &.active {
          color: var(--#{$variable-prefix}heading-color);
        }
      }

      &.active {
        >a {
          color: var(--#{$variable-prefix}heading-color);
        }
      }
    }

    >li {
      >a {
        padding: 0.5rem 1rem;
      }

      flex: 0 0 auto;
      position: relative;
      flex: 0 0 auto;
      position: relative;

      &:hover {
        border-color: transparent;
        padding: 0;
      }

      &:first-child {
        >a {
          padding-left: 0;
        }
      }
    }

    ul {
      border-left: 0;
    }

    >li.iq-ecomm-active {
      padding: 0;
    }
  }

  .product-offcanvas {
    .offcanvas-backdrop {
      &.show {
        opacity: 0;
      }
    }
  }

  .iq-product-menu-responsive {
    &.offcanvas-end {
      width: auto;
    }
  }
}

.iq-font-style {
  button {
    .iq-heading-text {
      color: var(--#{$variable-prefix}primary) !important;
    }

    &.btn-primary {
      .iq-heading-text {
        color: $white !important;
      }
    }
  }
}

.search-input {
  .iq-header-search-icon {
    top: 0.5rem;
    right: 1rem;
  }
}

.iq-navbar {
  .navbar-list {
    .nav-list-icon {
      svg {
        color: var(--bs-body-color);

        &:hover {
          color: var(--#{$variable-prefix}primary);
        }
      }
    }
  }
}

.product-offcanvas {
  padding-left: 6.25rem;
  padding-right: 6.25rem;
}

@media (max-width:1500px) {
  .product-offcanvas {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
}

@include media-breakpoint-down(xl) {
  .iq-nav-menu {
    li {
      &.active {
        color: var(--#{$variable-prefix}primary);
      }

      a {
        padding-left: 1rem;

        .right-icon {
          transition: all 100ms ease-in-out;
        }

        &:not(.collapsed) {
          &[aria-expanded="true"] {
            color: var(--#{$variable-prefix}primary);

            .right-icon {
              transition: all 100ms ease-in-out;
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }

  .offcanvas-end,
  .offcanvas-start {
    &.iq-product-menu-responsive {
      top: 3.7rem;
      position: fixed;
      padding-top: 3.7rem;
    }
  }

  .product-offcanvas {
    position: relative;

    .offcanvas-backdrop {
      position: fixed;
      top: 3.7rem !important;
    }
  }

  .iq-responsive-menu {
    display: block;
  }

  .iq-navbar {
    .navbar-collapse {
      &:not(.offcanvas-collapse) {
        position: absolute;
        top: 96%;
        left: 0;
        width: 100%;
        flex-grow: unset;
        background: var(--bs-gray-900);
        z-index: 1081;
        box-shadow: $box-shadow-sm;
        transition: all 400ms ease;
      }
    }
  }

  .horizontal-nav.mobile-offcanvas {
    background: var(--bs-gray-900);
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 80%;
    z-index: 1200;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
    transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out,
      -webkit-transform 0.3s ease-in-out;
  }
}

nav {
  .nav-item {
    input.form-control {
      height: 2.75rem;
    }
  }
}

@keyframes menu-sub-dropdown-animation-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes menu-sub-dropdown-animation-move-up {
  from {
    margin-top: 0.75rem;
  }

  to {
    margin-top: 0;
  }
}

@keyframes menu-sub-dropdown-animation-move-down {
  from {
    margin-bottom: 0.75rem;
  }

  to {
    margin-bottom: 0;
  }
}

.item-header-scroll {
  max-height: 25em;
  overflow-y: auto;
  overflow-x: hidden;
}

.header-notification {
  .thread {
    padding: .9375rem 1.875rem;
    transition: all 400ms ease;
  }

  a:hover {
    .thread {
      background-color: var(--bs-light-bg-subtle);
    }
  }
}
