.sidebar {
  display: block;
  position: fixed;
  width: 100%;
  max-width: var(--sidebar-width);
  top: 0;
  bottom: 0;
  transition: var(--sidebar-transition);
  transition-duration: var(--sidebar-transition-duration);
  transition-timing-function: var(--sidebar-transition-function-ease);
  z-index: 910;
  background-color: var(--#{$prefix}gray-900);
  color: var(--#{$variable-prefix}primary);
  .sidebar-body {
    padding-right: 1rem;
    overflow: hidden;
  }
  .data-scrollbar {
    max-height: 92vh;
  }
  .sidebar-header {
    border-bottom: $border-width solid var(--bs-border-color);
    margin-bottom: $spacer * 0.5;
    margin-bottom: 0.5rem;
    padding: .88em 2em;
  } 
  .sidebar-logo {
    transition-property: transform, opacity;
    transition-duration: var(--sidebar-transition-duration);
    transition-timing-function: var(--sidebar-transition-function-ease);
    margin-left: 1rem;
    margin-bottom: 0;
  }
  .navbar-brand {
    display: flex;
    align-items: center;
    padding: $spacer;
    svg {
      color: var(--#{$variable-prefix}primary);
    }
    .logo-title {
      margin-left: $spacer;
      margin-bottom: 0;
      transition: var(--sidebar-transition);
      transition-duration: var(--sidebar-transition-duration);
      transition-timing-function: var(--sidebar-transition-function-ease);
      transform: translateX(0%);
      opacity: 1;
      color: $dark;
    }
  }
  .logo-main {
    .logo-mini {
      display: none;
    }
    .logo-normal {
      display: block;
    }
  }
  .sidebar-toggle {
    position: absolute;
    top: 7em;
    left: auto;
    right: -1em;
    height: 2.281em;
    width: 2.281em;
    line-height: 2.281em;
    cursor: pointer;
    background: var(--#{$prefix}gray-900);
    z-index: -1;
    border-radius: 0.5em;
    transform: rotate(45deg);
    .icon {
      display: flex;
      transform: rotate(0);
      transition: var(--sidebar-transition);
      transition-duration: var(--sidebar-transition-duration);
      transition-timing-function: var(--sidebar-transition-function-ease);
    }
    .menu-btn {
      transform: rotate(-45deg);
      padding-left: 0.625em;
      padding-top: 0.1em;
      transition: all 0.45s ease;
      i {
        color: var(--bs-body-color);
      }
    }
  }
}

.sidebar-base {
  .nav-item {
    position: relative;
    transition-property: background-color, color, border-radius, transform,
      opacity, padding-left, padding-right;
    transition-duration: var(--sidebar-transition-duration);
    transition-timing-function: var(--sidebar-transition-function-ease);
    margin-top: 2px;
    color: var(--#{$variable-prefix}gray-600);

    &:not(.static-item) {
      padding-left: 1rem;
    }

    &.static-item {
      .default-icon {
        text-transform: uppercase;
        font-size: 0.75rem;
        color: var(--#{$prefix}gray-500);
        font-weight: $font-weight-semibold;
      }
    }

    .disabled {
      color: var(--#{$variable-prefix}gray-400);
      cursor: unset !important;
    }

    .nav-link {
      transition-property: background-color, color, border-radius, transform,
        opacity;
      transition-duration: var(--sidebar-transition-duration);
      transition-timing-function: var(--sidebar-transition-function-ease);
      cursor: pointer;
      line-height: 1.5;
      color: var(--bs-heading-color);
      &.active {
        transition-property: background-color, color, border-radius, transform,
          opacity;
        transition-duration: var(--sidebar-transition-duration);
        transition-timing-function: var(--sidebar-transition-function-ease);
        color: var(--#{$variable-prefix}primary);
      }

      &:hover {
        color: var(--#{$variable-prefix}primary);
      }

      &.static-item {
        margin-bottom: 0.5rem;

        .mini-icon {
          display: none;
        }
      }

      .item-name {
        flex: 1;
        margin-left: 0.5rem;
        transform: translateX(0);
        opacity: 1;
        font-size: 0.875rem;
        font-weight: $font-weight-semibold;
      }
      .icon {
        font-size: 1.25rem;
        color: var(--bs-heading-color);
      }

      .sidenav-mini-icon {
        opacity: 0;
        display: none;
      }
      text-transform: capitalize;
      &:not(.disabled) {
        .right-icon {
          color: rgba(var(--bs-gray-rgb), 0.3);
          transition: var(--sidebar-transition);
          transition-duration: var(--sidebar-transition-duration);
          transition-timing-function: var(
            --sidebar-transition-function-ease-in-out
          );
          display: flex;
        }
        .icon {
          display: flex;
        }
        color: $gray-600;
        &.active,
        &[aria-expanded="true"] {
          .right-icon {
            transition: var(--sidebar-transition);
            transition-duration: var(--sidebar-transition-duration);
            transition-timing-function: var(
              --sidebar-transition-function-ease-in-out
            );
            transform: rotate(90deg);
            color: var(--bs-white);
          }
        }
        &.active {
          &[aria-expanded="false"] {
            .right-icon {
              transition: var(--sidebar-transition);
              transition-duration: var(--sidebar-transition-duration);
              transition-timing-function: var(
                --sidebar-transition-function-ease-in-out
              );
              transform: rotate(0deg);
            }
          }
        }
      }
      padding: 0.625rem 1rem;
      display: flex;
      align-items: center;
      white-space: nowrap;
      text-transform: capitalize;
    }
    .sub-nav {
      .nav-link {
        .icon {
          font-size: 0.75rem;
        }
      }
    }
  }

  .sub-nav {
    padding: 0;
    margin: 0;
    overflow: hidden;
    transition: var(--sidebar-transition);
    transition-duration: var(--sidebar-transition-duration);
    transition-timing-function: var(--sidebar-transition-function-ease-in-out);
  }
}

body {
  --sidebar-width: #{$navbar-vertical-width};
  --sidebar-transition: #{$navbar-vertical-transition};
  --sidebar-transition-duration: #{$navbar-vertical-transition-duration};
  --sidebar-transition-function-ease: #{$navbar-vertical-transition-function-ease};
  --sidebar-transition-function-ease-in-out: #{$navbar-vertical-transition-function-ease-in-out};
}

@media (max-width: 1199px) {
  .sidebar {
    z-index: 11111;
    .sidebar-toggle {
      background: var(--#{$prefix}primary);
      transform: rotate(0);
      z-index: 1;
      right: 18px;
      .menu-btn {
        transform: rotate(0);
        padding: 0;
        i {
          color: var(--bs-white);
        }
      }
    }
  }
}
