
.sidebar {
    &.sidebar-default {
        .nav-link {
            &:not(.static-item){
                --shadow-dark-color: var(--#{$variable-prefix}primary-rgb);
                &.active, &[aria-expanded=true] {
                    background: var(--#{$variable-prefix}primary);
                    color: var(--bs-white);
                    box-shadow: 0 10px 20px -10px rgba(var(--shadow-dark-color), 38%);
                    .icon {
                        color: var(--bs-white);
                    }
                }
                &:hover {
                    &:not(.active):not([aria-expanded=true]) {
                        background: rgba(var(--bs-primary-rgb), 0.1);
                        color: var(--#{$variable-prefix}primary);
                        box-shadow: unset;
                        .icon {
                            color: var(--#{$variable-prefix}primary);
                        }
                    }
                }
            }
        }
    }
}
